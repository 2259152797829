<template>
    <div class="bo">
        <div class="top">
            <img src="@/assets/img/Slice35@2x.png" />
            <span>新增接送人</span>
        </div>
        <div class="name">
            <div class="name_top">
                <div class="ti"></div>
                <div>称呼</div>
            </div>
            <div>
                <input type="text" id="name" placeholder="请输入称呼" v-model="temp.user_name" />
            </div>
            <div>
                <div>接送人手机号</div>
            </div>
            <div>
                <input type="text" id="num" placeholder="请输入手机号" v-model="keyup" @keyup="keydown()" />
            </div>
        </div>

        <!-- <div class="time">
            <div class="name_top">
                <div class="ti"></div>
                <div>设置有效期</div>
            </div>
            <div>
                <div class="radio">
                    <span>长期有效</span>
                    <van-radio-group v-model="temp.radio">
                        <van-radio name="1" shape="square"></van-radio>
                    </van-radio-group>
                </div>
            </div>
            <div>
                <div class="date">
                    <div>
                        <span>自定义有效日期</span>
                        <van-radio-group v-model="temp.radio">
                            <van-radio name="2" shape="square"></van-radio>
                        </van-radio-group>
                    </div>

                    <div class="checktime">
                        <div @click="start_show = !start_show">{{ temp.start_time }}</div>
                        <div>-</div>
                        <div @click="end_show = !end_show">{{ temp.end_time }}</div>
                        <div><img src="../../assets/img/Slice59@2x.png" alt="" /></div>
                    </div>

                    <div class="datebtn">
                        <div @click="tody">今天</div>
                        <div @click="tomo">明天</div>
                        <div @click="week">本周</div>
                        <div @click="nextmonth">本月</div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="photo">
            <div>
                <span>上传人脸照</span>
            </div>
            <div>
                <van-uploader v-model="file" multiple :max-count="1" :after-read="afterread" />
            </div>
            <div class="msg">
                <div class="left">
                    <div>
                        <img src="../../assets/img/Slice58@2x.png">
                        <span>标准人脸图展示</span>
                    </div>
                    <div>
                        <img src="../../assets/img/zhenjianzhao.png">
                    </div>
                </div>
                <div class="right">
                    <div>
                        <img src="../../assets/img/Slice158@2x.png">
                        <span>标准人脸图展示</span>
                    </div>
                    <div>
                        <img src="../../assets/img/zi.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="btn">
            <img src="../../assets/img/button_submit@2x.png" @click="submit()">
        </div>

        <van-popup v-model="start_show" position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker v-model="date1" type="date" title="选择开始时间" @confirm="start_confirm" :formatter="formatter"
                @change="start_change" :min-date="minDate" @cancel="start_cancel" />
        </van-popup>
        <van-popup v-model="end_show" position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker v-model="date2" type="date" title="选择结束时间" @confirm="end_confirm" :formatter="formatter"
                @change="end_change" :min-date="minDate" @cancel="end_cancel" />
        </van-popup>
    </div>
</template>

<script>
import { getweek, getMonth } from '../../utils/getweek'
import { upload, addpick } from '../../api/pickup/list.js'
import { Notify } from 'vant';

export default {
    data() {
        return {
            start_show: false,
            end_show: false,
            temp: {
                // radio: "1",
                // start_time: "开始时间",
                // end_time: "结束时间",
                user_name:'',
                mobile: '',
                face: '',
            },
            file: [],
            date1: '',
            date2: '',
            minDate: new Date(),
            keyup: ''
        };
    },
    methods: {
        // 选择开始时间
        start_confirm(value) {
            console.log(value);
            var y = value.getFullYear()
            var m = value.getMonth() + 1
            var d = value.getDate()
            this.temp.start_time = y + '-' + m + '-' + d
            this.start_show = false
        },
        start_cancel() {
            this.temp.start_time = '开始时间'
            this.start_show = false
        },
        // 开始时间变化
        start_change() {
            var value = this.date1
            var y = value.getFullYear()
            var m = value.getMonth() + 1
            var d = value.getDate()
            this.temp.start_time = y + '-' + m + '-' + d
        },
        // 结束开始时间
        end_confirm(value) {
            console.log(value);
            var y = value.getFullYear()
            var m = value.getMonth() + 1
            var d = value.getDate()
            this.temp.end_time = y + '-' + m + '-' + d
            this.end_show = false
        },
        end_cancel() {
            this.temp.end_time = '结束时间'
            this.end_show = false
        },
        // 结束时间变化
        end_change() {
            var value = this.date2
            var y = value.getFullYear()
            var m = value.getMonth() + 1
            var d = value.getDate()
            this.temp.end_time = y + '-' + m + '-' + d
        },
        // 时间过滤
        formatter(type, val) {
            if (type === "year") {
                return val + "年";
            }
            if (type === "month") {
                return val + "月";
            }
            if (type === "day") {
                return val + "日";
            }
            return val;
        },
        // 今天
        tody() {
            var date = new Date()
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            var d = date.getDate()
            this.temp.start_time = y + '-' + m + '-' + d
            this.temp.end_time = y + '-' + m + '-' + d
        },
        //明天
        tomo() {
            var date = new Date()
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            var d = date.getDate()
            this.temp.start_time = y + '-' + m + '-' + d
            var dd = d + 1
            this.temp.end_time = y + '-' + m + '-' + dd
        },
        //本周
        week() {

            this.temp.start_time = getweek('s', 0)
            this.temp.end_time = getweek('e', 0)
        },
        // 本月
        nextmonth() {

            this.temp.start_time = getMonth('s', 0)
            this.temp.end_time = getMonth('e', 0)
        },
        // 上传图片
        async afterread(file){

            var f = await this.$api.compressImg(file.file)
            var formdata = new FormData()
            formdata.append('image', f)
            upload(formdata).then(res => {
                console.log(res);
                if(res.code == 200){
                    this.temp.face = res.data.url
                }else{
                    this.temp.face = '"kindergarten/photo/20230830/c9c2c7b7d02dd6cf2d6833fd424b0a3d7ef0baa5.png"'
                }
                
            })
        },
        submit(){
            if(!this.temp.user_name){
                Notify({ type: 'danger', message: '请输入家长称呼' });
                return;
            }
            if(!this.temp.mobile){
                Notify({ type: 'danger', message: '请输入正确的手机号' });
                return;
            }
            if(!this.temp.face){
                Notify({ type: 'danger', message: '请上传人脸照' });
                return;
            }
            addpick(this.temp).then(res => {
                if(res.code == 200){
                    Notify({ type: 'success', message: '添加成功' });
                    setTimeout(()=>{ history.go(-1) })
                }else{
                    Notify({ type: 'success', message: res.msg });
                }
            })
        },
        keydown(){
            console.log(this.keyup);
            var req = /^(13[0-9]|14[5|6|7|8|9]|15[0|1|2|3|5|6|7|8|9]|16[5|6]|17[1-8|18[0-9]|19[1|9])\d{8}$/
            if(req.test(this.keyup)){
                this.temp.mobile = this.keyup
            }else{
                
            }
        }
    },
};
</script>

<style lang="less" scoped src="./css/add.less"></style>
